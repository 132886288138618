<template>
    <label class="block" data-dashlane-label="true">
        <label v-if="label" :for="name" class="block mt-2 text-sm font-medium text-gray-700">
            {{ this.label }}
            <span class="text-red-500">{{ this.error }}</span>
        </label>

        <input :id="name" :name="name" type="text"
               v-bind:value="value"
               v-on:input="$emit('input', $event.target.value)"
               class="block mt-1 w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm mt-1"
               data-dashlane-rid="407939ea6eeee062" data-form-type="other">
    </label>
</template>

<script>
    export default {
        props: ['value','label','name', 'error'],

        methods: {
            focus() {
                this.$refs.input.focus()
            }
        }
    }
</script>

