<template>
    <label :class="[ checked ? 'bg-primary-50' : 'bg-white', 'relative border border-gray-500 rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none' ]">
        <input @change="onChange" ref="input" type="radio" :name="name" :value="inputValue" class="sr-only" aria-labelledby="project-type-0-label" aria-describedby="project-type-0-description-0 project-type-0-description-1">
        <div class="flex-1 flex">
            <div class="flex flex-col">
                <span id="project-type-0-label" class="block text-lg font-bold text-gray-900"> {{ label }} </span>
                <!--<span id="project-type-0-description-0" class="mt-1 flex items-center text-sm text-gray-500"> Lorem ipsum bla bla </span>-->
            </div>
        </div>
        <svg :class="[!checked ? 'invisible' : '', 'h-6 w-6 text-primary-600']" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
        </svg>
        <div :class="[ checked ? 'border-primary-500' : 'border-transparent', 'absolute -inset-px rounded-lg border-2 pointer-events-none' ]" aria-hidden="true"></div>
    </label>
</template>
<script>
export default {
    name: "ContactFormRadio",
    /* components: {}, */
     props: [
         'label',
         'name',
         'inputValue',
         'value'
     ],
    /* data: function () {
            return {
                someData: null
            }
        }, */
     computed: {
        checked: function () {
            return this.inputValue === this.value;
        }
     },
     methods: {
         onChange() {
             this.$emit('input', this.inputValue);
         }
         /*onInput(event) {
             this.$emit('input', event.target.value);
         },
         onEnterKeyDown() {
             const element = this.$refs.input;
             const currentIndex = Array.from(element.form.elements).indexOf(element);
             element.form.elements.item(
                 currentIndex < element.form.elements.length - 1 ?
                     currentIndex + 1 :
                     0
             ).focus();
             //this.$emit( 'onSubmit' );
         },*/
     },
    /* filters: {
            someFilter(value) {
                return value;
            }
        }, */
    mounted() {
    }
}
</script>
<!--<style scoped>

</style> -->
