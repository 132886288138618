import 'alpinejs'

/*import QuickMenu from "quickmenu";*/
/*new QuickMenu();*/

require('livewire-vue');

import Vue from 'vue';
import 'livewire-vue';

import axios from 'axios'

Vue.mixin(require('./base'));

Vue.component('space-catalog-form', require('./SpaceDetails/SpaceCatalogForm.vue').default);

Vue.component('loading-spinner', require('./MixForm/LoadingSpinner').default);
Vue.component('mix-form-input', require('./MixForm/Input').default);
Vue.component('mix-form-select', require('./MixForm/Select').default);
Vue.component('mix-button', require('./MixForm/Button').default);
Vue.component('xmas-snow', require('./components/XmasSnow').default);
Vue.component('mixup-logo', require('./components/MixupLogo').default);
Vue.component('new-contact-form', require('./components/NewContactForm').default);
Vue.component('new-contact-form-accepted', require('./components/NewContactFormAccepted').default);

const app = new Vue({
    el: '#app',

});

axios.defaults.withCredentials = true;
Vue.prototype.axios = axios;
