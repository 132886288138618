<template>
    <div>
        <h1 class="text-2xl lg:text-4xl font-bold mb-6 leading-normal text-white text-center">
            {{ __( step + '.headline' ) }}
        </h1>
        <div class="mt-32 my-16 rounded-lg mx-auto max-w-4xl">
            <p class="text-gray-500 text-center font-medium mt-4 text-base md:text-lg lg:text-xl">
                {{ __( step + '.p-1' ) }}
            </p>
            <p class="text-gray-500 text-center font-medium mt-4 text-base md:text-lg lg:text-xl">
                {{ __( step + '.p-2' ) }}
            </p>
            <div v-if="step === 'submitting'" class="mt-12 text-center items-center justify-center w-full">
                <svg xmlns="http://www.w3.org/2000/svg" class="mx-auto animate-spin-slow text-gray-500 icon icon-tabler icon-tabler-rotate-clockwise-2 w-32 h-32" width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"> <path stroke="none" d="M0 0h24v24H0z" fill="none"/> <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" /> <line x1="5.63" y1="7.16" x2="5.63" y2="7.17" /> <line x1="4.06" y1="11" x2="4.06" y2="11.01" /> <line x1="4.63" y1="15.1" x2="4.63" y2="15.11" /> <line x1="7.16" y1="18.37" x2="7.16" y2="18.38" /> <line x1="11" y1="19.94" x2="11" y2="19.95" /> </svg>
            </div>
            <div v-if="step === 'accepted'" class="mt-12 text-center items-center justify-center w-full">
                <svg xmlns="http://www.w3.org/2000/svg" class="mx-auto text-primary-700 icon icon-tabler icon-tabler-arrow-bottom-square w-32 h-32" width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"> <path stroke="none" d="M0 0h24v24H0z" fill="none"/> <path d="M12 7v14" /> <path d="M9 18l3 3l3 -3" /> <path d="M14 3v4h-4v-4z" /> </svg>
            </div>
        </div>
        <div class="mb-32 block border border-gray-300" :class="[ step === 'submitting' ? 'opacity-0' : '' ]" style="min-height:550px;">
            <div id="SOIDIV_MixUpErstgespraech" data-so-page="MixUpErstgespraech" data-height="550" data-style="border: 0px solid #d8d8d8; min-width: 290px; max-width: 900px;" data-psz="00"></div>
        </div>
    </div>
</template>
<script>
export default {
    name: "NewContactFormAccepted",
    components: {},
    /* components: {}, */
    props: [
            'locale'
        ],
     data: function () {
        return {
            //put this in <div id="SOIDIV_MixUpErstgespraech":    data-so-qry-prm="name=Oliver%20Eberlei&email=oliver%40mixup.world&phone=12345"
            step: "submitting",
            //step: "accepted",
        }
    },
    methods: {

    },
    /* filters: {
            someFilter(value) {
                return value;
            }
        }, */
    mounted() {
        let self = this;
        setTimeout( () => {
            self.step = "accepted";
        }, 5000 );
    },

    computed: {
        language: function () {
            if( this.locale === 'en' ) {
                return {
                    "submitting": {
                        "headline": "Please wait.",
                        'p-1': "Your submission will be checked automatically!",
                        "p-2": "Please don\'t close this window!",
                    },
                    "accepted": {
                        'headline': 'Congratulations,',
                        'p-1': 'your submission was checked automatically and it looks promising!',
                        'p-2': 'Please select a date and time below for a 15-minute call with one of our experts!',
                    }
                };
            }

            return {
                "submitting": {
                    "headline": "Bitte warten.",
                    'p-1': "Deine Angaben werden automatisch geprüft!",
                    "p-2": "Bitte schließe dieses Fenster nicht!",
                },
                "accepted": {
                    'headline': 'Glückwunsch,',
                    'p-1': 'deine Angaben wurde automatisch geprüft und wirken vielversprechend!',
                    'p-2': 'Wähle jetzt deine Zeit für ein 15-Minütiges Gespräch mit einem unserer Experten aus.',
                }
            };
        }
    }
}
</script>
<style>
    html {
        scroll-behavior: smooth;
    }
</style>
