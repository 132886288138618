<template>
    <div>
        <Snow
            :active='true'
            zIndex='2'
            :wind='-8'
            :swing='3'
            color="#ffffff"
        />
    </div>
</template>
<script>
import Snow from 'vue-niege';

export default {
    name: "XmasSnow",
     components: {
        Snow,
     },

}
</script>