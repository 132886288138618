<template>
    <div class="py-4">
        <button @click="onClick" type="button" :disabled="!show" :class="[ show ? 'bg-primary-600 hover:bg-primary-700' : 'bg-gray-600 opacity-50 cursor-not-allowed', 'transition duration-500 inline-flex items-center px-6 pr-7 py-3 border border-transparent shadow-sm text-lg font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500' ]">
            <svg v-if="show" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-check h-6 w-6 mr-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"> <path stroke="none" d="M0 0h24v24H0z" fill="none"/> <circle cx="12" cy="12" r="9" /> <path d="M9 12l2 2l4 -4" /> </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-edit-circle h-6 w-6 mr-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"> <path stroke="none" d="M0 0h24v24H0z" fill="none"/> <path d="M12 15l8.385 -8.415a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3z" /> <path d="M16 5l3 3" /> <path d="M9 7.07a7.002 7.002 0 0 0 1 13.93a7.002 7.002 0 0 0 6.929 -5.999" /> </svg>
            <span v-if="show">Ok</span>
            <span v-else>Bitte ausfüllen</span>
        </button>
    </div>
</template>
<script>
export default {
    name: "ContactFormOk",
    /* components: {}, */
     props: [
        'show'
     ],
    /* data: function () {
            return {
                someData: null
            }
        }, */
    /* computed: {
            someComputed: function (value) {
                return value;
            }
        }, */
     methods: {
        onClick() {
            this.$emit('submit');
        }
     },
    /* filters: {
            someFilter(value) {
                return value;
            }
        }, */
    mounted() {
    }
}
</script>
<!--<style scoped>

</style> -->
