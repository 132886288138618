<template>
    <div class="my-12 bg-gray-200 rounded-lg px-6 pt-8 mb-96">
        <form>
            <contact-form-text :step="0" :max-step="maxStep" :label="__( 'contact-form.input.name' )" name="name" v-model="formName" @submit="nextStep"></contact-form-text>
            <contact-form-text :step="1" :max-step="maxStep" :label="__( 'contact-form.input.company' )" name="company" v-model="formCompany" @submit="nextStep"></contact-form-text>
            <contact-form-text :step="2" :max-step="maxStep" :label="__( 'contact-form.input.web' )" name="web" v-model="formWeb" @submit="nextStep"></contact-form-text>
            <contact-form-radio-group :step="3" :max-step="maxStep" :value="formEventCount" :label="__( 'contact-form.input.eventCount' )" @submit="nextStep">
                <contact-form-radio :label="__( 'contact-form.input.eventCountMultiple' )" input-value="multiple" name="event_count" v-model="formEventCount"></contact-form-radio>
                <contact-form-radio :label="__( 'contact-form.input.eventCountOne' )" input-value="1" name="event_count" v-model="formEventCount"></contact-form-radio>
                <contact-form-radio :label="__( 'contact-form.input.eventCountZero' )" input-value="0" name="event_count" v-model="formEventCount"></contact-form-radio>
            </contact-form-radio-group>
            <contact-form-radio-group :step="4" :max-step="maxStep" :value="formEventSize" :label="__( 'contact-form.input.eventSize' )" @submit="nextStep">
                <contact-form-radio label="0 - 50" input-value="0 - 50" name="event_size" v-model="formEventSize"></contact-form-radio>
                <contact-form-radio label="50 - 150" input-value="50 - 150" name="event_size" v-model="formEventSize"></contact-form-radio>
                <contact-form-radio label="150 - 250" input-value="150 - 250" name="event_size" v-model="formEventSize"></contact-form-radio>
                <contact-form-radio label="250 - 1000" input-value="250 - 1000" name="event_size" v-model="formEventSize"></contact-form-radio>
                <contact-form-radio label="1000 - 2000" input-value="1000 - 2000" name="event_size" v-model="formEventSize"></contact-form-radio>
                <contact-form-radio :label="__( 'contact-form.input.eventSizeLarge' )" input-value="2000+" name="event_size" v-model="formEventSize"></contact-form-radio>
            </contact-form-radio-group>
            <contact-form-radio-group :step="5" :max-step="maxStep" :value="formEventExhibitor" :label="__( 'contact-form.input.eventExhibitor' )" @submit="nextStep">
                <contact-form-radio :label="__( 'contact-form.input.eventExhibitorYesPaid' )" input-value="yes, paid" name="event_exhibitor" v-model="formEventExhibitor"></contact-form-radio>
                <contact-form-radio :label="__( 'contact-form.input.eventExhibitorYesSomeFree' )" input-value="yes, some free" name="event_exhibitor" v-model="formEventExhibitor"></contact-form-radio>
                <contact-form-radio :label="__( 'contact-form.input.eventExhibitorYesAllFree' )" input-value="yes, all free" name="event_exhibitor" v-model="formEventExhibitor"></contact-form-radio>
                <contact-form-radio :label="__( 'contact-form.input.eventExhibitorNo' )" input-value="no" name="event_exhibitor" v-model="formEventExhibitor"></contact-form-radio>
            </contact-form-radio-group>
            <contact-form-text :step="6" :max-step="maxStep" :label="__( 'contact-form.input.companySize' )" name="company_size" v-model="formCompanySize" @submit="nextStep"></contact-form-text>
            <contact-form-text :step="7" :max-step="maxStep" :label="__( 'contact-form.input.companyRole' )" name="company_role" v-model="formCompanyRole" @submit="nextStep"></contact-form-text>

            <contact-form-radio-group :step="8" :max-step="maxStep" :value="formEventType" :label="__( 'contact-form.input.eventType' )" @submit="nextStep">
                <contact-form-radio :label="__( 'contact-form.input.eventTypeExhibitionStage' )" input-value="exhibition and stage" name="event_type" v-model="formEventType"></contact-form-radio>
                <contact-form-radio :label="__( 'contact-form.input.eventTypeExhibitionNoStage' )" input-value="exhibition, no stage" name="event_type" v-model="formEventType"></contact-form-radio>
                <contact-form-radio :label="__( 'contact-form.input.eventTypeWebinar' )" input-value="webinar" name="event_type" v-model="formEventType"></contact-form-radio>
                <contact-form-radio :label="__( 'contact-form.input.eventTypeNetworking' )" input-value="networking" name="event_type" v-model="formEventType"></contact-form-radio>
                <contact-form-radio :label="__( 'contact-form.input.eventTypeWorkshop' )" input-value="workshop" name="event_type" v-model="formEventType"></contact-form-radio>
                <contact-form-radio :label="__( 'contact-form.input.eventTypeParty' )" input-value="party" name="event_type" v-model="formEventType"></contact-form-radio>
            </contact-form-radio-group>
            <contact-form-text :step="9" :max-step="maxStep" :label="__( 'contact-form.input.email' )" name="email" v-model="formEmail" @submit="nextStep"></contact-form-text>
            <contact-form-text :step="10" :max-step="maxStep" :label="__( 'contact-form.input.phone' )" name="phone" v-model="formPhone" @submit="onSubmit"></contact-form-text>

            <button v-if="!submitDisabled" @click="onSubmit" type="button" :disabled="submitDisabled" :class="[ submitDisabled ? 'bg-gray-500 cursor-not-allowed' : 'bg-primary-600 hover:bg-primary-700', 'disabled:opacity-0 transition duration-100 my-12 inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-3xl font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                <svg v-show="!sending" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-checkup-list h-10 w-10 mr-4" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"> <path stroke="none" d="M0 0h24v24H0z" fill="none"/> <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" /> <rect x="9" y="3" width="6" height="4" rx="2" /> <path d="M9 14h.01" /> <path d="M9 17h.01" /> <path d="M12 16l1 1l3 -3" /> </svg>
                <svg v-show="sending" xmlns="http://www.w3.org/2000/svg" class="animate-spin icon icon-tabler icon-tabler-rotate-clockwise-2 h-10 w-10 mr-4" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"> <path stroke="none" d="M0 0h24v24H0z" fill="none"/> <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" /> <line x1="5.63" y1="7.16" x2="5.63" y2="7.17" /> <line x1="4.06" y1="11" x2="4.06" y2="11.01" /> <line x1="4.63" y1="15.1" x2="4.63" y2="15.11" /> <line x1="7.16" y1="18.37" x2="7.16" y2="18.38" /> <line x1="11" y1="19.94" x2="11" y2="19.95" /> </svg>
                {{ __( 'contact-form.input.submit' )}}
            </button>
            <h3 v-show="sending" class="text-2xl font-bold mb-4 text-gray-700 block w-full">{{ __( 'contact-form.input.submitting' )}}</h3>
            <p v-show="sending" class="text-lg mb-12 text-gray-700 block w-full">{{ __( 'contact-form.input.submitting-2' )}}</p>
        </form>
    </div>
</template>
<script>
import ContactFormText from "./NewContactForm/ContactFormText";
import ContactFormRadioGroup from "./NewContactForm/ContactFormRadioGroup";
import ContactFormRadio from "./NewContactForm/ContactFormRadio";

export default {
    name: "NewContactForm",
    components: {ContactFormRadio, ContactFormRadioGroup, ContactFormText},
    /* components: {}, */
    props: [
            'locale'
        ],
     data: function () {
        return {
            sending: false,
            formName: '',
            formCompany: '',
            formWeb: '',
            formEventCount: '',
            formEventSize: '',
            formEventExhibitor: '',
            formEventType: '',
            formCompanySize: '',
            formCompanyRole: '',
            formEmail: '',
            formPhone: '',
            maxStep: 0,
            token: '',
            /*formName: 'Oliver Eberlei',
            formCompany: 'MixUp',
            formWeb: 'mixup.world',
            formEventCount: 'multiple',
            formEventSize: '150 - 250',
            formEventExhibitor: 'yes, paid',
            formEventType: 'exhibition and stage',
            formCompanySize: '4',
            formCompanyRole: 'Founder',
            formEmail: 'oliver@mixup.world',
            formPhone: '',
            maxStep: 10,*/
        }
    },
    methods: {
        generateToken: function() {
            var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            var token = '';
            for(var i = 0; i < 32; i++) {
                token += chars[Math.floor(Math.random() * chars.length)];
            }
            return token;
        },
        onSubmit: function() {
            this.sending = true;

            let formData = this.getFormData();
            formData.append( 'submit', 'submit' );

            this.axios.post( '/api/contact/submit', formData)
                .then(response => {
                    let self = this;
                    setTimeout( () => {
                        location.href = '/accepted';
                    }, 1000);
                });

        },
        getFormData: function() {
            let formData = new FormData();
            formData.append( 'name', this.formName );
            formData.append( 'company', this.formCompany );
            formData.append( 'companySize', this.formCompanySize );
            formData.append( 'companyRole', this.formCompanyRole );
            formData.append( 'url', this.formWeb );
            formData.append( 'eventCount', this.formEventCount );
            formData.append( 'eventSize', this.formEventSize );
            formData.append( 'eventExhibitor', this.formEventExhibitor );
            formData.append( 'eventType', this.formEventType );
            formData.append( 'email', this.formEmail );
            formData.append( 'phone', this.formPhone );
            formData.append( 'maxStep', this.maxStep );
            formData.append( 'token', this.token );
            formData.append( 'locale', this.locale );

            return formData;
        },
        nextStep: function (currentStep) {
            this.maxStep = Math.max( this.maxStep, currentStep + 1 );

            let formData = this.getFormData();

            this.axios.post( '/api/contact/submit', formData)
                .then(response => {

                });
        }
    },
    /* filters: {
            someFilter(value) {
                return value;
            }
        }, */
    mounted() {
        this.token = this.generateToken() + "#" + ( new Date ).getTime();
    },

    computed: {
        submitDisabled: function() {
            return !this.formPhone || this.sending === true;
        },
        language: function () {
            if( this.locale === 'en' ) {
                return {
                    "contact-form": {
                        "input": {
                            "name": "Your name",
                            "company": "The name of your company",
                            "web": "Your company website",
                            "eventCount": "Number of planned events",
                            "eventCountMultiple": "Multiple events per year",
                            "eventCountOne": "One event planned",
                            "eventCountZero": "Currently none planned",
                            "eventSize": "How many participants attend your events?",
                            "eventSizeLarge": "Over 2000",
                            "eventExhibitor": "Do you have exhibitors or sponsors, which require a booth presence?",
                            "eventExhibitorYesPaid": "Yes, we sell the booths",
                            "eventExhibitorYesSomeFree": "Yes, but some booths are free",
                            "eventExhibitorYesAllFree": "Yes, but we don't sell them",
                            "eventExhibitorNo": "No",
                            "companySize": "How many employees does your company have?",
                            "companyRole": "What is your role in the company?",
                            "eventType": "Only 3 questions left! Which event type describes your planned events best?",
                            "eventTypeExhibitionStage": "Exhibition with talks",
                            "eventTypeExhibitionNoStage": "Exhibition without talks",
                            "eventTypeWebinar": "Talks and networking",
                            "eventTypeNetworking": "Free networking",
                            "eventTypeWorkshop": "Workshop",
                            "eventTypeParty": "Party / Get-together",
                            "email": "What is your e-mail address?",
                            "phone": "Which phone number can we use to reach you?",
                            "submit": "Send",
                            "submitting": "Please wait. Your submission will be checked automatically!",
                            "submitting-2": "This can take up to 30 seconds. Please don\'t close this window!",
                        }
                    }
                };
            }


            return {
                "contact-form": {
                    "input": {
                        "name": "Dein Name",
                        "company": "Der Name deines Unternehmens",
                        "web": "Eure Webseite",
                        "eventCount": "Anzahl der geplanten Veranstaltungen",
                        "eventCountMultiple": "Mehrere im Jahr",
                        "eventCountOne": "Eine Veranstaltung geplant",
                        "eventCountZero": "Bisher keine geplant",
                        "eventSize": "Wie viele Teilnehmer haben eure Veranstaltungen?",
                        "eventSizeLarge": "Über 2000",
                        "eventExhibitor": "Habt ihr Aussteller oder Sponsoren, die Messestände benötigen?",
                        "eventExhibitorYesPaid": "Ja, wir verkaufen die Stände",
                        "eventExhibitorYesSomeFree": "Ja, aber manche sind kostenlos",
                        "eventExhibitorYesAllFree": "Ja, aber wir verkaufen sie nicht",
                        "eventExhibitorNo": "Nein",
                        "companySize": "Wie viele Mitarbeiter hat euer Unternehmen?",
                        "companyRole": "Was ist deine Rolle im Unternehmen?",
                        "eventType": "Noch 3 Fragen! Welcher Veranstaltungstyp beschreibt eure Veranstaltungen am besten?",
                        "eventTypeExhibitionStage": "Messe mit Vorträgen",
                        "eventTypeExhibitionNoStage": "Messe ohne Vorträge",
                        "eventTypeWebinar": "Vorträge mit Networking",
                        "eventTypeNetworking": "Freies Networking",
                        "eventTypeWorkshop": "Workshop",
                        "eventTypeParty": "Party / Get-together",
                        "email": "Wie lautet deine Email Adresse?",
                        "phone": "Unter welcher Telefonnummer können wir dich erreichen?",

                        "submit": "Senden",
                        "submitting": "Bitte warten. Deine Angaben werden automatisch geprüft!",
                        "submitting-2": "Dies kann bis zu 30 Sekunden dauern. Bitte schließe dieses Fenster nicht!",
                    }
                }
            };
        }
    }
}
</script>
<style>
    html {
        scroll-behavior: smooth;
    }
</style>
