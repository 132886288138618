<template>
    <div v-show="maxStep >= step" :id="'step-' + step" class="mb-16 py-4">
        <label :for="name" class="text-2xl font-bold mb-12 text-gray-700 block w-full">{{ label }}</label>
        <div class="mt-1 border-b-2 border-gray-500 focus-within:border-indigo-600">
            <input
                ref="input" v-bind:value="value" v-on:input="onInput" v-on:keydown.enter.prevent="onEnterKeyDown"
                type="text" :id="name" :name="name" class="px-4 py-4 block w-full border-0 border-b border-transparent bg-white focus:border-primary-600 ring-0 focus:ring-0 text-lg" placeholder="" />
        </div>
        <contact-form-ok ref="ok" @submit="onOkPressed" :show="!!value"></contact-form-ok>
    </div>
</template>
<script>
import ContactFormOk from "./ContactFormOk";
export default {
    name: "ContactFormText",
    components: {ContactFormOk},
    /* components: {}, */
     props: [
         'label',
         'name',
         'value',
         'step',
         'maxStep',
     ],
    /* data: function () {
            return {
                someData: null
            }
        }, */
    /* computed: {
            someComputed: function (value) {
                return value;
            }
        }, */
     methods: {
         onInput(event) {
             this.$emit('input', event.target.value);
         },
         onSubmit() {
             this.$emit('submit', this.step);
             this.selectNextFormElement();
             //location.hash = "#step-" + (this.step + 1);
         },
         onOkPressed() {
             this.onSubmit();
         },
         selectNextFormElement() {
             this.$nextTick(() => {
                 const element = this.$refs.input;
                 const currentIndex = Array.from(element.form.elements).indexOf(element);
                 const nextIndex = currentIndex < element.form.elements.length - 2 ? currentIndex + 2 : 0
                 const nextElement = element.form.elements.item(nextIndex);
                 nextElement.focus();

                 const scrollElement = document.getElementById('step-' + (this.step+1));
                 console.log(scrollElement);
                 scrollElement.scrollIntoView();
             });
         },
         onEnterKeyDown() {
             if(!this.value) {
                 return;
             }

             this.onSubmit();
         },
     },
    /* filters: {
            someFilter(value) {
                return value;
            }
        }, */
    mounted() {
    }
}
</script>
<!--<style scoped>

</style> -->
