<template>
    <div>
        <div v-if="mode === 'createPage' || mode === 'editPage'">
            <div v-if="mode === 'createPage'" class="text-l font-bold mb-2">
                Create new catalog page
            </div>
            <div v-if="mode === 'editPage'" class="text-l font-bold mb-2">
                Edit catalog page
            </div>
            <mix-form-input label="Page headline" name="page_name" :error="pageHeadlineError" v-model="pageHeadline"></mix-form-input>
            <mix-form-select label="Page type":error="pageTypeError" name="selected_catalog" v-model="pageType">
                <option value="0">Select page type</option>
                <option value="1">Video</option>
                <option value="2">Download</option>
                <option value="3">Link</option>
            </mix-form-select>
            <mix-form-input v-if="pageType === '1'" label="Video URL" name="page_content_url" :error="pageContentError" v-model="pageContent"></mix-form-input>
            <mix-form-input v-if="pageType === '2'" label="Download URL" name="page_content_url" :error="pageContentError" v-model="pageContent"></mix-form-input>
            <mix-form-input v-if="pageType === '3'" label="Link URL" name="page_content_url" :error="pageContentError" v-model="pageContent"></mix-form-input>
            <mix-form-input v-if="pageType != '0'" label="Description (Optional)" name="description" v-model="pageDescription"></mix-form-input>
            <mix-form-input v-if="pageType != '0'" label="Button Label (Optional)" name="button_text_override" v-model="pageButtonLabel"></mix-form-input>
            <mix-button v-if="mode === 'createPage'" :disabled="isSaving" :is-working="isSaving" :is-primary="true" @click="onCreateNewPageConfirmed">
                Create
            </mix-button>
            <mix-button v-if="mode === 'editPage'" :disabled="isSaving" :is-working="isSaving" :is-primary="true" @click="onEditPageConfirmed">
                Update
            </mix-button>
            <mix-button @click="onCreateNewPageCancelled">
                Cancel
            </mix-button>
        </div>
        <div v-if="mode === 'create'">
            <div class="text-l font-bold mb-2">
                Create new catalog
            </div>
            <mix-form-input label="New catalog headline" name="new_catalog_name" :error="catalogHeadlineError" v-model="catalogHeadline"></mix-form-input>
            <mix-button :disabled="isSaving" :is-working="isSaving" :is-primary="true" @click="onCreateNewConfirmed">
                Create
            </mix-button>
            <mix-button @click="onCreateNewCancelled">
                Cancel
            </mix-button>
        </div>
        <div v-if="mode === 'edit'">
            <div class="text-l font-bold mb-2">
                Edit catalog
            </div>
            <mix-form-input label="Catalog headline" name="new_catalog_name" :error="catalogHeadlineError" v-model="catalogHeadline"></mix-form-input>
            <mix-button :disabled="isSaving" :is-working="isSaving" :is-primary="true" @click="onEditConfirmed">
                Save
            </mix-button>
            <mix-button @click="onCreateNewCancelled">
                Cancel
            </mix-button>
        </div>
        <div v-if="mode === 'index'">
            <!--<mix-form-select label="Select catalog" @input="onSelectedCatalogChange" :error="selectedCatalogError" name="selected_catalog" v-model="selectedCatalog">
                <option value="">None / Create New</option>
                <option v-for="catalog in selectCatalogs" :value="catalog.id">{{ catalog.headline }}</option>
            </mix-form-select>-->
            <mix-button v-if="selectedCatalog === ''" @click="onCreateNewClicked" :is-primary="true">
                Create new Catalog
            </mix-button>
            <div v-else>
                <h2 class="font-bold text-lg">{{ catalogHeadline }}</h2>
                <mix-button :is-working="isLoading" @click="onEditClicked" :is-primary="true">
                    Edit
                </mix-button>
                <div class="mt-1 mb-1">
                    Pages: {{ selectedCatalogPages.length }}
                    <div v-for="page in selectedCatalogPages" class="w-full flex space-x-2 content-end border-t border-gray-200 py-1 mb-1">
                        <p class="flex-grow flex py-2 border border-white text-sm font-medium mt-1">
                            <svg v-if="page.content_type == 1" xmlns="http://www.w3.org/2000/svg" class="-mt-1 icon icon-tabler icon-tabler-movie" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"> <path stroke="none" d="M0 0h24v24H0z" fill="none"/> <rect x="4" y="4" width="16" height="16" rx="2" /> <line x1="8" y1="4" x2="8" y2="20" /> <line x1="16" y1="4" x2="16" y2="20" /> <line x1="4" y1="8" x2="8" y2="8" /> <line x1="4" y1="16" x2="8" y2="16" /> <line x1="4" y1="12" x2="20" y2="12" /> <line x1="16" y1="8" x2="20" y2="8" /> <line x1="16" y1="16" x2="20" y2="16" /> </svg>
                            <svg v-if="page.content_type == 2" xmlns="http://www.w3.org/2000/svg" class="-mt-1 icon icon-tabler icon-tabler-file-download" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"> <path stroke="none" d="M0 0h24v24H0z" fill="none"/> <path d="M14 3v4a1 1 0 0 0 1 1h4" /> <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /> <line x1="12" y1="11" x2="12" y2="17" /> <polyline points="9 14 12 17 15 14" /> </svg>
                            <svg v-if="page.content_type == 3" xmlns="http://www.w3.org/2000/svg" class="-mt-1 icon icon-tabler icon-tabler-external-link" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"> <path stroke="none" d="M0 0h24v24H0z" fill="none"/> <path d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5" /> <line x1="10" y1="14" x2="20" y2="4" /> <polyline points="15 4 20 4 20 9" /> </svg>
                            {{ page.headline }}
                        </p>
                        <mix-button @click="onPageEditClicked( page )">
                            Edit
                        </mix-button>
                        <mix-button :is-working="isDestroying === page.id" :is-danger="true" @click="onPageDestroyClicked( page )">
                            Delete
                        </mix-button>
                    </div>
                </div>

                <mix-button v-if="selectedCatalogPages.length < 3 && !isLoading" @click="onCreatePageClicked">
                    Create new page
                </mix-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: [
            'catalogs'
        ],

        data() {
            return {
                mode: 'index',
                selectCatalogs: {},
                selectedCatalog: '',
                selectedCatalogData: {},
                selectedCatalogError: '',
                catalogHeadline: '',
                catalogHeadlineError: '',

                pageHeadline: '',
                pageHeadlineError: '',
                pageType: '0',
                pageTypeError: '',
                pageContent: '',
                pageContentError: '',
                pageDescription: '',
                pageImageUrl: '',
                pageButtonLabel: '',

                editingPage: null,

                isSaving: false,
                isDestroying: 0,
                isLoading: false,
            };
        },

        computed: {
            selectedCatalogPages() {
                if( this.selectedCatalogData && this.selectedCatalogData.space_catalog_entries ) {
                    return this.selectedCatalogData.space_catalog_entries;
                }

                return [];
            }
        },

        mounted() {
            this.observer = new IntersectionObserver(entries => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting && entry.intersectionRatio > 0) {
                        this.onBecameVisible();
                    } else {
                        this.onBecameInvisible();
                    }
                });
            });

            this.observer.observe(this.$el);
            this.selectCatalogs = this.catalogs;
        },

        methods: {
            reset() {
                this.mode = 'index';
                this.selectedCatalog = '';
                this.selectedCatalogData = {};
                this.selectedCatalogError = '';
                this.catalogHeadline = '';
                this.catalogHeadlineError = '';

                this.resetPage();

                this.isSaving = false;
                this.isLoading = false;
                this.isDestroying = false;
            },
            showFooter(isVisible) {
                window.livewire.emit('show_footer', !!isVisible);
            },
            onBecameVisible() {
                this.reset();
                this.selectedCatalog = document.getElementById('catalog-form-input').value;

                this.onSelectedCatalogChange();
            },

            onBecameInvisible() {

            },
            onSelectedCatalogChange() {
                window.livewire.emit('new_catalog', this.selectedCatalog);

                if( this.selectedCatalog ) {
                    this.isLoading = true;
                    this.axios.get( '/api/space-catalog/' + this.selectedCatalog)
                        .then(response => {
                            this.isLoading = false;
                            this.selectedCatalogData = response.data;
                            this.selectedCatalog = this.selectedCatalogData.id;
                            this.catalogHeadline = this.selectedCatalogData.headline;
                        })
                        .catch(error => {
                            this.isLoading = false;
                            console.error( error );
                        })
                }
            },
            onCreateNewConfirmed() {
                if( !this.catalogHeadline ) {
                    this.catalogHeadlineError = 'Please enter a headline';
                    return;
                }

                this.catalogHeadlineError = '';
                this.isSaving = true;
                this.showFooter(true);

                let formData = new FormData();
                formData.append( 'headline', this.catalogHeadline );

                this.axios.post( '/api/space-catalog/store', formData)
                    .then(response => {
                        this.isSaving = false;
                        this.mode = 'index';
                        console.log( response.data );
                        this.selectCatalogs = response.data.catalogs;
                        this.selectedCatalogData = response.data.new_catalog;
                        this.selectedCatalog = this.selectedCatalogData.id;
                        this.catalogHeadline = this.selectedCatalogData.headline;

                        this.$emit( 'new_catalog', this.selectedCatalog );
                        window.livewire.emit('new_catalog', this.selectedCatalog);
                    })
                    .catch(error => {
                        this.isSaving = false;
                        this.catalogHeadlineError = error;
                    })

            },
            onCreateNewCancelled() {
                this.mode = 'index';
                this.showFooter(true);
            },
            onCreateNewClicked() {
                this.catalogHeadlineError = '';
                this.catalogHeadline = '';
                this.isSaving = false
                this.mode = 'create';
                this.showFooter(false);
            },
            onEditClicked() {
                this.catalogHeadlineError = '';
                this.catalogHeadline = this.selectedCatalogData.headline;
                this.mode = 'edit';
                this.showFooter(false);
            },
            onEditConfirmed() {
                if( !this.catalogHeadline ) {
                    this.catalogHeadlineError = 'Please enter a headline';
                    return;
                }

                this.catalogHeadlineError = '';
                this.isSaving = true;
                this.showFooter(true);

                let formData = new FormData();
                formData.append( 'headline', this.catalogHeadline );

                this.axios.post( '/api/space-catalog/' + this.selectedCatalog, formData)
                    .then(response => {
                        this.isSaving = false;
                        this.mode = 'index';
                        console.log( response.data );

                        let catalogIndex = this.selectCatalogs.findIndex((obj => obj.id === response.data.id));
                        this.selectCatalogs[ catalogIndex ] = response.data;
                    })
                    .catch(error => {
                        this.isSaving = false;
                        this.catalogHeadlineError = error;
                    })
            },
            onCreatePageClicked() {
                this.isSaving = false
                this.resetPage();
                this.mode = 'createPage';
                this.showFooter(false);
            },
            validatePageValues() {
                this.pageHeadlineError = '';
                this.pageTypeError = '';
                this.pageContentError = '';

                if( !this.pageHeadline ) {
                    this.pageHeadlineError = 'Please enter a headline';
                }

                if( this.pageType === '0' ) {
                    this.pageTypeError = 'Please choose a type';
                }

                if( !this.pageContent ) {
                    this.pageContentError = 'Please enter a URL';
                }

                return !this.pageHeadlineError && !this.pageTypeError && !this.pageContentError;
            },
            onCreateNewPageConfirmed() {
                if( !this.validatePageValues() ) {
                    return;
                }

                this.isSaving = true;
                this.showFooter(true);

                let formData = new FormData();
                formData.append( 'space_catalog_id', this.selectedCatalog );
                formData.append( 'headline', this.pageHeadline );
                formData.append( 'content_type', this.pageType );
                formData.append( 'content_url', this.pageContent );
                formData.append( 'description', this.pageDescription );

                if( this.pageImageUrl ) {
                    formData.append('image_url', this.pageImageUrl);
                }

                if( this.pageButtonLabel ) {
                    formData.append('button_text_override', this.pageButtonLabel);
                }

                this.axios.post( '/api/space-catalog-entry/store', formData)
                    .then(response => {
                        this.isSaving = false;
                        this.mode = 'index';

                        this.selectedCatalogData = response.data;
                        this.selectedCatalog = this.selectedCatalogData.id;
                        this.catalogHeadline = this.selectedCatalogData.headline;
                    })
                    .catch(error => {
                        this.isSaving = false;
                        this.pageHeadlineError = error;
                    })

            },
            onEditPageConfirmed() {
                if( !this.validatePageValues() ) {
                    return;
                }

                this.isSaving = true;
                this.showFooter(true);

                let formData = new FormData();
                formData.append( 'space_catalog_id', this.selectedCatalog );
                formData.append( 'headline', this.pageHeadline );
                formData.append( 'content_type', this.pageType );
                formData.append( 'content_url', this.pageContent );
                formData.append( 'description', this.pageDescription );

                if( this.pageImageUrl ) {
                    formData.append('image_url', this.pageImageUrl);
                }

                if( this.pageButtonLabel ) {
                    formData.append('button_text_override', this.pageButtonLabel);
                }

                this.axios.post( '/api/space-catalog-entry/' + this.editingPage.id, formData)
                    .then(response => {
                        this.isSaving = false;
                        this.mode = 'index';

                        this.selectedCatalogData = response.data;
                        this.selectedCatalog = this.selectedCatalogData.id;
                        this.catalogHeadline = this.selectedCatalogData.headline;
                    })
                    .catch(error => {
                        this.isSaving = false;
                        this.pageHeadlineError = error;
                    })

            },
            onCreateNewPageCancelled() {
                this.mode = 'index';
                this.showFooter(true);
            },
            resetPage() {
                this.pageHeadline = '';
                this.pageHeadlineError = '';
                this.pageType = '0';
                this.pageTypeError = '';
                this.pageContent = '';
                this.pageContentError = '';
                this.pageDescription = '';
                this.pageImageUrl = '';
                this.pageButtonLabel = '';
            },
            onPageEditClicked( page ) {
                this.resetPage();
                this.editingPage = page;
                this.pageHeadline = page.headline;
                this.pageType = page.content_type;
                this.pageContent = page.content_url;
                this.pageDescription = page.description;
                this.pageImageUrl = page.image_url;
                this.pageButtonLabel = page.button_text_override;
                this.mode = 'editPage';

                this.showFooter(false);

                this.$nextTick(() => {
                    this.$forceUpdate();
                });

            },
            onPageDestroyClicked( page ) {
                if( confirm( "Do you really want to delete the page?" ) ) {
                    this.isDestroying = page.id;
                    this.axios.get( '/api/space-catalog-entry/' + page.id + '/destroy')
                        .then(response => {
                            this.isDestroying = 0;

                            this.selectedCatalogData = response.data;
                            this.selectedCatalog = this.selectedCatalogData.id;
                            this.catalogHeadline = this.selectedCatalogData.headline;
                        })
                        .catch(error => {
                            this.isDestroying = 0;
                            this.pageHeadlineError = error;
                        })
                }
            },
        }
    }
</script>
