// Location in resources/js/base.js

module.exports = {
    methods: {
        /**
         * Translate the given key.
         */
        __(key, replace = {}) {
            if( !key ) {
                return "";
            }

            const elements = key.split( '.' );
            var translation = key;
            let values = this.language;

            for(let i = 0; i < elements.length; ++i ) {
                if( values[ elements[ i ] ] !== undefined ) {
                    translation = values[ elements[ i ] ];
                    values = values[ elements[ i ] ];
                } else {
                    translation = key;
                }
            }

            Object.keys(replace).forEach(function (key) {
                translation = translation.replace(':' + key, replace[key])
            });

            return translation
        },
        /*locale() {
            return this.$page.props.locale;
        }*/
    },
}
