<template>
    <label class="block" data-dashlane-label="true">
        <label v-if="label" :for="name" class="block mt-2 text-sm font-medium text-gray-700">
            {{ this.label }}
            <span class="text-red-500">{{ this.error }}</span>
        </label>

        <select :name="name" :id="name" v-bind:value="value"  @input="$emit('input', $event.target.value)" class="mt-1 block w-full border border-gray-300 shadow-sm text-sm rounded-md text-gray-900">
            <slot></slot>
        </select>
    </label>
</template>

<script>
    export default {
        props: ['value','label','name', 'error'],

        methods: {
            focus() {
                this.$refs.input.focus()
            },
        }
    }
</script>

