<template>
    <svg class="h-8 w-auto sm:h-10 text-xmas-500" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 432 295.5" style="enable-background:new 0 0 432 295.5;" xml:space="preserve"

    >
                <g id="Layer_1">
                <path fill="none" stroke="#C54245" stroke-width="53" stroke-linecap="round" stroke-linejoin="round" d="M38.9,255.9v-60c0-33.1,26.9-60,60-60l0,0c33.1,0,60,26.9,60,60l0,0l0,0 M158.9,255.9v-60
                c0-33.1,26.9-60,60-60l0,0c33.1,0,60,26.9,60,60l0,0c0,33.1,26.9,60,60,60l0,0c33.1,0,60-26.9,60-60l0,0v-60"/>
            </g>
        <g id="Layer_2">
                <polyline fill="none" stroke="#C54245" stroke-width="70" stroke-linecap="round" stroke-linejoin="round" points="98.9,45.9 98.9,45.9 98.9,45.9 	"/>
            <polyline fill="none" stroke="#C54245" stroke-width="70" stroke-linecap="round" stroke-linejoin="round"points="218.9,45.9 218.9,45.9 218.9,45.9 	"/>
            <polyline fill="none" stroke="#C54245" stroke-width="70" stroke-linecap="round" stroke-linejoin="round"points="338.9,45.9 338.9,45.9 338.9,45.9 	"/>
            </g>
        </svg>
</template>
<script>
export default {
    name: "MixupLogo",
    /* components: {}, */
    /* props: [
            'someProperty'
        ], */
    /* data: function () {
            return {
                someData: null
            }
        }, */
    /* computed: {
            someComputed: function (value) {
                return value;
            }
        }, */
    /* methods: {
            someFunction: function (value) {
            return value;
            }
        }, */
    /* filters: {
            someFilter(value) {
                return value;
            }
        }, */
    mounted() {
    }
}
</script>
<!-- <style scoped>
</style> -->
