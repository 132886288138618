<template>
    <div v-show="maxStep >= step" class="mb-16 py-4">
        <legend :id="'step-' + step" class="text-2xl font-bold mb-12 text-gray-700 block w-full">{{ label }}</legend>
        <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 gap-x-4">
            <slot></slot>
        </div>
        <input type="hidden" name="st" ref="hidden"/>
    </div>
</template>
<script>
export default {
    name: "ContactFormRadioGroup",
    /* components: {}, */
     props: [
         'label',
         'value',
         'step',
         'maxStep',
     ],
    watch: {
        value: function (val, oldVal) {
            this.$emit('submit', this.step);

            this.$nextTick(() => {
                const element = this.$refs.hidden;
                const currentIndex = Array.from(element.form.elements).indexOf(element);
                const nextIndex = currentIndex < element.form.elements.length - 1 ? currentIndex + 1 : 0
                const nextElement = element.form.elements.item(nextIndex);
                nextElement.focus();

                const scrollElement = document.getElementById('step-' + (this.step+1));
                console.log(scrollElement);
                scrollElement.scrollIntoView();
            });
        },
    },
    /* data: function () {
            return {
                someData: null
            }
        }, */
    /* computed: {
            someComputed: function (value) {
                return value;
            }
        }, */
     /*methods: {

     },*/
    /* filters: {
            someFilter(value) {
                return value;
            }
        }, */
    mounted() {
    }
}
</script>
<!--<style scoped>

</style> -->
