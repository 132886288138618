<template>
    <button type="submit"
            @click.prevent="$emit( 'click' )"
            :disabled="disabled"
            :class="[
                this.classes,
                this.isPrimary === true ? 'text-white bg-primary-500 disabled:bg-primary-200 hover:bg-primary-600 ' : '',
                this.isDanger === true ? 'text-white bg-red-600 disabled:bg-red-200 hover:bg-red-700 ' : '',
                this.isDanger !== true && this.isPrimary !== true ? 'border-gray-300 text-gray-700 hover:bg-gray-50 bg-white' : '',
                'inline-flex items-center px-4 mt-1 py-2 text-sm font-medium border border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 rounded-md shadow-sm']"

            >
        <loading-spinner v-show="isWorking"></loading-spinner>
        <slot></slot>
    </button>
</template>

<script>
    export default {
        props: [
            'isPrimary',
            'isDanger',
            'isWorking',
            'disabled',
            'classes',
        ],
        methods: {

        },
    }
</script>

